var AwesomeFilter = function (options) {
    'use strict';

    var $ = jQuery; // enable jQuery
    var self = this;

    self.options = options;

    self.bind = function () {
        $(self.options.control_selector, self.options.controls).on('click', function () {
            var filter = $(this).attr('data-filter');
            filter = filter == '*' ? filter : '.awesome-filter-' + filter;
            $('.filter-item ' + self.options.control_selector, self.options.controls).removeClass(self.options.control_active);
            $(this).addClass(self.options.control_active);

            self.filter(filter);
        });
    }

    self.filter = function(filter){
        $('.filter-item', self.options.container).addClass('la-hidden');
        $('.filter-item' + filter, self.options.container).removeClass('la-hidden');
        $('.filter-item').trigger('cssClassChanged');
    }

    self.init = function () {
        self.bind();
    }
}